import React, { useState } from 'react'
import { graphql, Link, useStaticQuery, navigate } from 'gatsby'
import Layout from '../components/layout'
import ProductTile from '../components/productTile'
import PageHeader from '../components/pageHeader'
import SEO from '../components/seo'
import { useLunr } from 'react-lunr'

export const query = graphql`
{
    localSearchPages {
        index
        store
    }
    products: allStrapiProduct (filter: {publishedAt: {gt: "2020-01-17T13:26:54.386Z"}}) {
        nodes {
            slug
            name
            category {
                name
            }
            cover {
                localFile {
                childImageSharp {
                    gatsbyImageData
                }
                }
            }
        }
    }
    categories: allStrapiCategory {
        nodes {
            name
            slug
        }
    }
}
`


export default function Saved() {
    const data = useStaticQuery(query)
    const ISSERVER = typeof window === "undefined";
    const [searchQuery, setSearchQuery] = useState(null)
    const index = data.localSearchPages.index
    const store = data.localSearchPages.store
    const results = useLunr(searchQuery, index, store) // use lunr engine to search
    const wishList = !ISSERVER ? JSON.parse(localStorage.getItem('wishList')) || [] : []
    const products = searchQuery ? results : data.products.nodes.filter(product => wishList.includes(product.slug))

    const categories = data.categories.nodes

    const handleClear = () => {
        if (!ISSERVER) {
            (localStorage.clear())
        }

        //clear products from state
        navigate("/saved")
    }

    return (
        <Layout>
            <SEO
                title='Live The Dream | Your Favourites | Custom Furniture'
            />
            <PageHeader
                title="Your Favourites"
            />
            <div className="container mx-auto">
                <div className="w-full lg:w-3/4 mx-auto flex flex-col gap-4 lg:gap-0 lg:flex-row items-start justify-center">
                    <div className="w-full lg:w-1/2 flex gap-2 lg:gap-4 flex-wrap">
                        {categories.map(category => {
                            return (
                                <Link
                                    to={"/inspiration/" + category.slug}
                                    activeClassName="bg-primary text-white"
                                    className="text-center py-2 md:py-1 w-24 mt-2 text-xs rounded-full border  border-primary hover:bg-primary hover:text-white focus:bg-primary focus:text-white focus:outline-none focus:ring-0 active:bg-primary active:text-white active:shadow-lg transition duration-150 ease-in-out "
                                >
                                    {category.name}
                                </Link>
                            )
                        })}
                        <Link
                            to="/inspiration"
                            activeClassName="bg-primary text-white"
                            className="text-center py-2 md:py-1 w-[93px] mt-2 text-xs rounded-full border border-primary hover:bg-primary hover:text-white focus:bg-primary focus:text-white focus:outline-none focus:ring-0 active:bg-primary active:text-white active:shadow-lg transition duration-150 ease-in-out "
                        >
                            All
                        </Link>
                        <Link
                            to="/saved"
                            activeClassName="bg-primary text-white"
                            className="text-center py-2 md:py-1 w-[93px] mt-2 text-xs rounded-full border border-primary hover:bg-primary hover:text-white focus:bg-primary focus:text-white focus:outline-none focus:ring-0 active:bg-primary active:text-white active:shadow-lg transition duration-150 ease-in-out "
                        >
                            Liked
                        </Link>
                    </div>
                    <input
                        type="text"
                        className='w-full lg:w-1/2 rounded-md border border-primary py-2 px-4 bg-[#C4C4C4] focus:outline-none'
                        placeholder='Search'
                        value={searchQuery}
                        onChange={e => setSearchQuery(e.target.value)}
                    />
                </div>
                <div className="my-6 w-full grid lg:grid-cols-3 gap-6 lg:gap-12">
                    {products.map(product => <ProductTile product={product} />)}
                </div>

                <div className="px-20 w-full flex justify-center">
                    {wishList.length > 0 ?
                        <button className='border border-black p-1' onClick={handleClear}>clear all</button>
                        :
                        <span>This list is empty, browse the <Link className='text-primary' to="/inspiration">catalog</Link></span>
                    }
                </div>
            </div>
        </Layout>
    )
}
